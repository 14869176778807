export const getVerifyPassword = "api/v1/user/verify";
export const loginData = "api/v1/user/login_data";
export const getPersonas = "api/v1/user/personas";
export const askMessage = "api/v1/user/ask";
export const generateSummary = "api/v1/user/generate-summary";
export const logOut = "api/v1/user/logout";
export const adminLogin = "api/v1/user/admin-verify";
export const adminHearingLogin = "api/v1/user/hearing-test-verify";
export const personaUpdate = "api/v1/user/persona-update";
export const audioTranscribe = "api/v1/user/transcribe";
export const getDefaultPersona = "api/v1/user/persona-get-default";

