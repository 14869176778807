
import { FC } from "react";

const Feedback: FC = () => {
    return (
        <>
            <iframe className="full-screen-iframe"
                src="https://forms.office.com/pages/responsepage.aspx?id=2270kNJ7m0OeVycOpdijtaUhF8tpwY9OqBDTtiirWdlUQjhQWldIWjBOQ1pYSFRUUkc4TzlIMjBMRy4u&embed=true"
                // border="0"
                // width="0"
                // marginheight="0"
                style={{
                    border: "none",
                    width: "100%",
                    height: "100%"
                }}
                allowFullScreen
            />

        </>
    )
}
export default Feedback;