
import { FC } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authURL } from "./auth/googleApi";
import Slider from "react-slick";

const ChatModeSelection: FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className="chat-mode">
                <Container>
                    <Row className="justify-content-center align-items-center g-xl-5 g-lg-4 g-3">
                        <Col xxl={4} xl={5} lg={6} md={7}>
                            <div className="mode-box">
                                <Image src={process.env.PUBLIC_URL + '/assets/img/class.png'} />
                                <h2>
                                    <span>Simulation</span>  Clinic
                                </h2>
                                <p>
                                    Details about how this
                                    works or Video
                                </p>
                                <Button variant="" onClick={() => {
                                    localStorage.setItem('chat-mode', 'paid')
                                    navigate("/persona-selection")
                                }
                                }>Start</Button>
                            </div>
                        </Col>
                        <Col xxl={4} xl={5} lg={6} md={7}>
                            <div className="mode-box">
                                <Image src={process.env.PUBLIC_URL + '/assets/img/ind.png'} />
                                <h2>
                                    <span>Independent</span> Practice
                                </h2>
                                <p>
                                    Details about how this
                                    works or Video
                                </p>
                                <Button variant="" onClick={() => {
                                    navigate("/persona-selection");
                                    localStorage.setItem('chat-mode', 'free')
                                }
                                }>Start</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}
export default ChatModeSelection;