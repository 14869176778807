import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import ChatModeSelection from '../pages/ChatModeSelection';
import Login from '../pages/auth/Login';
import PersonaSelection from '../pages/PersonaSelection';
import ChatScreen from '../pages/ChatScreen';
import Feedback from '../pages/Feedback';
import NotFound from '../pages/NotFound';


const Navigation = () => {
    return (
        <>
            <Router
                basename='/'
            >
                <MainContent />
            </Router>
        </>
    );
};

const MainContent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [endSummary, setEndSummary] = useState<boolean>(false);

    useEffect(() => {
        const loginCheck = localStorage.getItem("normalLogin");
        if (!loginCheck) {
            navigate("./");
        }
        // else {
        //     navigate("./chat-mode");
        // }
    }, []);


    return (
        <>
            {location.pathname !== '/' && location.pathname !== '/feedback' && <Header setEndConversationSummary={setEndSummary} />}
            <main className='align-content-center'>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/chat-mode" element={<ChatModeSelection />} />
                    <Route path="/persona-selection" element={<PersonaSelection />} />
                    <Route path="/chat-screen" element={<ChatScreen endSummaryButton={endSummary} />} />
                    <Route path="/feedback" element={<Feedback />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
            {location.pathname !== '/feedback' && <Footer />}
        </>
    )
}
export default Navigation;