import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

const Logo: FC = () => {
    const location = useLocation();

    return (
        <>
            <div className="logo">
                <Link to={location.pathname === '/' ? "#" : location.pathname === "/chat-screen" ? "#" : "/chat-mode"}>
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Logo" />
                </Link>
            </div >
        </>
    )
};
export default Logo;