import { FC } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound: FC = () => {

    const navigate = useNavigate();
    return (
        <>
            <div className="text-center page-not-found pb-4">
                <h1 className="text-white">404</h1>
                <p>Sorry, we can't find the page you're looking for.</p>
                <Button onClick={() => navigate("/")} variant="secondary">Back to Home</Button>
            </div>
        </>
    )
};
export default NotFound;