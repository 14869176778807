import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

interface StepsProps {
    continueCase: boolean;
    explainTest: boolean;
}
const Steps: FC<StepsProps> = (props) => {
    return (
        <>
            <div className="step-main mt-lg-0 mt-2 mb-lg-0 mb-3" id="no-print">
                <Row className="justify-content-center align-items-center g-2">
                    <Col xs="auto">
                        <div className="step-box step1" step-active="true">
                            <div className="circle-main">
                                <div className="step-circle">
                                    <span>1</span>
                                </div>
                            </div>
                            <h6>Case History</h6>
                        </div>
                    </Col>
                    <Col xxl={2} md={3} xs>
                        <div step-active={props.continueCase && "true" || props.explainTest && "true"} className="step-line"></div>
                    </Col>
                    <Col xs="auto">
                        <div className="step-box step2" step-active={props.continueCase && "true" || props.explainTest && "true"}>
                            <div className="circle-main">
                                <div className="step-circle">
                                    <span>2</span>
                                </div>
                            </div>
                            <h6>Hearing Test</h6>
                        </div>
                    </Col>
                    <Col xxl={2} md={3} xs>
                        <div step-active={props.explainTest && "true"} className="step-line"></div>
                    </Col>
                    <Col xs="auto">
                        <div className="step-box step3" step-active={props.explainTest && "true"}>
                            <div className="circle-main">
                                <div className="step-circle">
                                    <span>3</span>
                                </div>
                            </div>
                            <h6>Explain Results</h6>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default Steps;