import { FC, useEffect, useRef, useState, } from "react";
import { Col, Container, InputGroup, Row, Stack } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { adminHearingVerify, generateSummary } from "../../../api/request";
import { closeIconFlat, eyeOff, eyeOn, submitIcon } from "../../../utils/Icons";
import CustomError from "../../../utils/CustomError";

interface HearingTest {
    show: boolean;
    explainTest: (value: boolean) => void;
    pdfShow: (value: boolean) => void;
    continueHistory: (value: boolean) => void;
    onClose: () => void;
    personaDetails: any;
}

const HearingTest: FC<HearingTest> = (props) => {


    const [verify, setVerify] = useState(false);
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [login, setLogin] = useState<any>(false);
    const [chatType, setChatType] = useState<any>("");

    useEffect(() => {
        if (props.show) {
            props.continueHistory(true)
        }
    }, [props.show])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [verify]);

    useEffect(() => {
        const chatMode = localStorage.getItem('chat-mode');
        setChatType(chatMode);

    }, [verify]);

    const handleSummary = async () => {
        const param = localStorage.getItem('Persona');
        const response = await generateSummary(param);
        if (response?.flag) {
            // console.log(response?.result?.message);
        }
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const verifyHandle = async (e: any) => {
        const checked = e?.target?.checked
        if (chatType === "paid") {
            if (checked) {
                setVerify(true);
            }
            else {
                setVerify(false);
                setLogin(false);
            }
        }
        else {
            if (checked) {
                setLogin(true);
            }
            else {
                setLogin(false);

            }
        }

    }

    const handleLogin = async () => {
        const matchPassword = props.personaDetails[0]?.password_results && props.personaDetails[0]?.password_results === password;
        if (matchPassword) {
            setLogin(true);
            setVerify(false);
        }
        else {
            setError("show-error");
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setError("");
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    }, [error]);


    return (
        <>
            <Modal className="hearing-test-modal" size="xl" show={props.show} onHide={props.onClose} keyboard={false} backdrop="static" centered>
                <div className="step-box step1" step-active="true">
                    <div className="circle-main">
                        <div className="step-circle">
                            <span>2</span>
                        </div>
                    </div>
                </div>
                <Row className="justify-content-center g-0">
                    <Col xxl={11}>
                        <Modal.Header>
                            <Modal.Title>
                                Proceed to Hearing Test
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="my-2">You will now perform the hearing assessment for this client.
                            </p>
                            <ul>
                                <li>Use the separate hearing testing simulation tool.  </li>
                                <li>Do not close this browser tab.</li>
                                <li>Return to this screen after completing the assessment. </li>
                                <li>Prepare to explain the findings clearly to the patient and make appropiate recommendations. </li>
                            </ul>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox"
                                    checked={login}
                                    onClick={(e: any) => {
                                        verifyHandle(e)
                                    }}
                                    className="text-white" label=" Show patient's test results" />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer className="justify-content-end mt-3 g-1 text-center">
                            <Col md="auto" xs="auto">

                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        handleSummary();
                                        props.explainTest(true);
                                        props.onClose();
                                        if (login) {
                                            props.pdfShow(true);
                                        } else {
                                            props.pdfShow(false);
                                        }
                                    }}
                                >
                                    Explain Test Results
                                </Button>
                            </Col>
                            <Col md="auto" xs="auto">
                                <Button variant="primary" className=""
                                    onClick={() => {
                                        props.onClose();
                                        props.continueHistory(false);
                                    }}
                                >
                                    Continue Case History
                                </Button>
                            </Col>
                        </Modal.Footer>
                    </Col>
                </Row>
                {chatType === "paid" && (
                    <>
                        {verify && (
                            <>
                                <div className="bg-black"></div>
                                <button type="button" onClick={() => {
                                    setVerify(false);
                                    setLogin(false)
                                }} className="btn btn-secondary btn-squares">{closeIconFlat()}</button>
                                <section className="login-page hearing-admin p-0">
                                    <Container className="">
                                        <Row className=" align-items-center justify-content-center">
                                            <Col xxl={7} lg={9} md={12} className="text-center">
                                                <Stack className="mt-4">
                                                    <InputGroup>
                                                        <input
                                                            className="form-control"
                                                            autoComplete="new-password"
                                                            type={showPassword !== true ? "password" : "text"}
                                                            onChange={(e: any) =>
                                                                setPassword(e.target.value)
                                                            }
                                                            onKeyDown={(key: any) => {
                                                                if (key.key === "Enter") {
                                                                    handleLogin()
                                                                }
                                                            }}
                                                            ref={inputRef}
                                                            placeholder="Enter you password"
                                                        />
                                                        <Button
                                                            onClick={() => setShowPassword(prevState => !prevState)}
                                                            type="button"
                                                            variant="eye-icon"
                                                        >
                                                            {showPassword ? eyeOn() : eyeOff()}
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            variant=""
                                                            className="submit-btn"
                                                            onClick={() =>
                                                                handleLogin()
                                                            }
                                                        >
                                                            {submitIcon()}
                                                        </Button>
                                                    </InputGroup>
                                                </Stack>
                                                <CustomError class={`mt-3 ${error}`} message="The Password you entered is incorrect." />
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            </>
                        )}
                    </>
                )}
            </Modal >
        </>
    )
}
export default HearingTest;