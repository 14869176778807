import { FC } from "react";
import { Image } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface Persona {
    imgSrc: string,
    about: string,
    name: string,
    coming: string,
}

const Persona: FC<Persona> = (props) => {
    return (
        <>
            <div className="main-person">
                <div className="person-img">
                    <Image src={props?.imgSrc} alt="" className={props?.coming && props?.coming === "comming_soon" ? "blur-img" : ""} />
                    {props?.coming && props?.coming === "comming_soon" ?
                        <>
                            <div className="comming-content">
                                <h5>Coming Soon</h5>
                            </div>
                        </> :
                        <>
                            <div className="hover-content">
                                <h6 className="mt-0">{props?.name}</h6>

                                {/* <p className="">
                                    {props?.about}
                                </p> */}
                                <div className="show-more-tooltip">
                                    {props?.about && (
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    {props?.about}
                                                </Tooltip>
                                            }
                                        >
                                            <div className="show-more">Description</div>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                </div>
                <h6>{props?.name}</h6>
            </div >
        </>
    )
}
export default Persona;