
import { FC, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";



const EndConversationSummary: FC = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className="end-summary text-center w-100 bg-transparent ps-0" id="no-print">
                <div className="case-summary p-0 bg-transparent">
                    <div className="case-des p-0 bg-transparent">
                        <Button variant="secondary" onClick={() => window.print()}>Download Summary PDF</Button>
                        <Button className="ms-1" variant="primary" onClick={() => navigate('/chat-mode')}>Back to Home</Button>
                    </div>
                </div>
            </div>

        </>
    )
}
export default EndConversationSummary;