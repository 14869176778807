
import { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


const CaseHistorySummary: FC = () => {
    return (
        <>
            <span className="main-history text-center mb-2">
                <span className="case-summary">
                    <span className="justify-content-center row" id="no-print">
                        <span className="text-start col-auto">
                            <h6>Download Summary PDF</h6>
                            <span onClick={() => window.print()}>
                                <span className="pdf-grp d-flex align-item-center">
                                    <img width={56} src={process.env.PUBLIC_URL + '/assets/img/pdf.png'} alt="Logo" />
                                    <span className="ms-1">
                                        <h5 className="mb-1">Conversation Completed!</h5>
                                        <p className="m-0">Response as sent by Chatbot</p>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>

                </span >
            </span >
        </>
    )
}
export default CaseHistorySummary;