import { FC, useEffect, useState } from "react";
import { Col, Container, FormLabel, InputGroup, Row, Stack } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { eyeOff, eyeOn, submitIcon } from "../../utils/Icons";
import CustomError from "../../utils/CustomError";
import { adminVerify, getPersonas, personaUpdate } from "../../api/request";
import { useLocation } from "react-router-dom";

interface freeModal {
    show: boolean;
    onClose: () => void;
    handleClick: () => void;
}

const FreeModal: FC<freeModal> = (props) => {
    const [listKey, setListKey] = useState<any>({
        provide: "Open",
        follow: "Cooperative",
        reaction: "Calm",
    });

    const handleSave = () => {
        localStorage.setItem("listKey", JSON.stringify(listKey));
        props.onClose();
        props.handleClick();
    };

    return (
        <>

            <Modal className="admin-modal" size="lg" show={props.show} onHide={props.onClose} keyboard={false} backdrop={"static"} centered>
                <Modal.Header className="justify-content-between row  g-0 text-center py-lg-2 py-1 px-lg-4 px-2 border-bottom">
                    <Modal.Title className="col text-start">
                        Select Traits
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="update-persona p-lg-4 p-2 border-bottom">
                    <Row className="g-2">
                        <Col xl={12}>
                            <InputGroup className="d-block">
                                <FormLabel >Willingness to provide information</FormLabel>
                                <div key={`inline-radio`} className="d-block">
                                    <Form.Check
                                        label="Open"
                                        name="provide"
                                        type="radio"
                                        value="Open"
                                        id={`inline-radio-1`}
                                        checked={listKey.provide === "Open"}
                                        onChange={(e: any) =>
                                            setListKey((prevState: any) => (
                                                {
                                                    ...prevState,
                                                    provide: e?.target?.value,
                                                }))
                                        }
                                    />
                                    <Form.Check
                                        value="Guarded"
                                        label="Guarded"
                                        name="provide"
                                        type="radio"
                                        checked={listKey.provide === "Guarded"}
                                        id={`inline-radio-2`}
                                        onChange={(e: any) =>
                                            setListKey((prevState: any) => (
                                                {
                                                    ...prevState,
                                                    provide: e?.target?.value,
                                                }))
                                        }
                                    />
                                </div>
                            </InputGroup>
                        </Col>
                        <Col xl={12}>
                            <InputGroup className="my-2 d-block">
                                <FormLabel>Willingness to follow recommendations</FormLabel>

                                <div key={`inline-radio`} className="d-block">
                                    <Form.Check
                                        label="Cooperative"
                                        name="follow"
                                        type="radio"
                                        value="Cooperative"
                                        id={`inline-radio-3`}
                                        checked={listKey.follow === "Cooperative"}
                                        onChange={(e: any) =>
                                            setListKey((prevState: any) => (
                                                {
                                                    ...prevState,
                                                    follow: e?.target?.value,
                                                }))
                                        }
                                    />
                                    <Form.Check
                                        value="Resistant"
                                        label="Resistant"
                                        name="follow"
                                        type="radio"
                                        id={`inline-radio-4`}
                                        checked={listKey.follow === "Resistant"}

                                        onChange={(e: any) =>
                                            setListKey((prevState: any) => (
                                                {
                                                    ...prevState,
                                                    follow: e?.target?.value,
                                                }))
                                        }
                                    />
                                </div>
                            </InputGroup>
                        </Col>
                        <Col xl={12}>
                            <InputGroup className="d-block">
                                <FormLabel>Emotional reaction to recommendations</FormLabel>

                                <div key={`inline-radio`} className="d-block">
                                    <Form.Check
                                        label="Calm"
                                        name="reaction"
                                        type="radio"
                                        value="Calm"
                                        id={`inline-radio-5`}
                                        checked={listKey.reaction === "Calm"}
                                        onChange={(e: any) =>
                                            setListKey((prevState: any) => (
                                                {
                                                    ...prevState,
                                                    reaction: e?.target?.value,
                                                }))
                                        }
                                    />
                                    <Form.Check
                                        value="Anxious"
                                        label="Anxious"
                                        name="reaction"
                                        type="radio"
                                        id={`inline-radio-6`}
                                        checked={listKey.reaction === "Anxious"}
                                        onChange={(e: any) =>
                                            setListKey((prevState: any) => (
                                                {
                                                    ...prevState,
                                                    reaction: e?.target?.value,
                                                }))
                                        }
                                    />
                                </div>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-end mb-0 g-1 text-center py-lg-2 py-1 px-lg-4 px-2">
                    <Col md="auto" xs="auto">
                        <Button
                            className="ms-2"
                            variant="secondary"
                            onClick={() => {
                                handleSave()
                            }}
                        >
                            Start Chat
                        </Button>
                        <Button
                            className="ms-2"
                            variant="primary"
                            onClick={() => {
                                props.onClose()
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal >
        </>
    )
}
export default FreeModal;