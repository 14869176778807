import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import CaseHistorySummary from "./CaseHistorySummary";
import Suggestion from "./Suggestion";
import EndConversationSummary from "./EndConversationSummary";
import { getMessage } from "../api/request";
import Loader from "./Loader";
import { micIcon, sendIcon } from "../utils/Icons";
import { useNavigate } from "react-router-dom";
import CustomError from "../utils/CustomError";

interface ChatBoxProps {
    speechTexts: string;
    endSummary: boolean;
    explainTest: boolean;
    personaDetails: any;
    micLoader: boolean;
    // continueCaseHistory: boolean;
    onEndConversation: (value: boolean) => void;
    openHearingTestModal: (value: boolean) => void;
    openSpeechModal: (value: boolean) => void;
}

const ChatBox: FC<ChatBoxProps> = (props) => {

    const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';
    const [messages, setMessages] = useState<any[]>([]);
    const [caseHistory, setCaseHistory] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [endConversationSummary, setEndConversationSummary] = useState(props.endSummary);
    const [chatType, setChatType] = useState<any>("");
    const [error, setError] = useState({
        show: false,
        class: "",
        message: ""
    });
    useEffect(() => {
        const timer = setTimeout(() => {
            setError({
                show: false,
                class: "",
                message: "",
            });
        }, 9000);
        return () => {
            clearTimeout(timer);
        };
    }, [error]);

    const [textValue, setTextValue] = useState<string>(props.speechTexts || "");

    useEffect(() => {
        if (props.speechTexts) {
            setTextValue((prevText) => {
                const updated = prevText + " " + props.speechTexts;
                return updated;
            });
        }
    }, [props.speechTexts]);


    useEffect(() => {
        const chatMode = localStorage.getItem('chat-mode');
        setChatType(chatMode);
    }, [chatType])

    useEffect(() => {
        if (endConversationSummary) {
            fetchMessage();
        }
    }, [endConversationSummary]);

    useEffect(() => {
        if (caseHistory) {
            fetchMessage();
        }
    }, [caseHistory])

    useEffect(() => {
        if (props.endSummary) {
            setEndConversationSummary(props.endSummary);
        }
    }, [props.speechTexts, props.endSummary]);

    useEffect(() => {
        if (props.endSummary) {
            setTextValue("Create summary");
        }
    }, [props.endSummary]);


    const fetchMessage = async () => {
        setLoader(true);
        setTextValue("");
        const chatMode = localStorage.getItem('chat-mode');
        const persona = localStorage.getItem('Persona');
        const listKey: any = localStorage.getItem('listKey');
        const parsedListKey = JSON.parse(listKey);
        const finalChatMode = chatMode === "paid" ? "initialappt_1_case_history" : "free_chat";
        let chatTraits = {
            info_sharing: chatMode === "paid" ? props.personaDetails[0]?.willingness_to_provide_information : parsedListKey?.provide,
            compliance: chatMode === "paid" ? props.personaDetails[0]?.willingness_to_follow_recommendations : parsedListKey?.follow,
            emotional_state: chatMode === "paid" ? props.personaDetails[0]?.emotional_reaction_to_recommendations : parsedListKey?.reaction
        }
        if (endConversationSummary || textValue === "Create summary") {
            setMessages((prev: any) => [
                ...prev,
                { text: "Create summary", type: 'user', createHistory: false, endConversation: true }
            ]);
        } else if (textValue === "create case history") {
            setMessages((prev: any) => [
                ...prev,
                { text: "create case history", type: 'user', createHistory: true, endConversation: false }
            ]);
        } else {
            setMessages((prev: any) => [
                ...prev,
                { text: textValue, type: 'user', createHistory: false, endConversation: false }
            ]);
        }
        console.log(messages, "message")
        const mainMessage = endConversationSummary ? "Create summary" : textValue;
        let payload = {
            chat_mode: "main",
            message: mainMessage,
            persona: persona,
            scenario: finalChatMode,
            ...chatTraits
        }
        const response = await getMessage(payload);
        if (response?.flag) {
            setTextValue("");
            setLoader(false);
            if (textValue === "create case history") {
                setMessages((prev: any) => [
                    ...prev,
                    { text: parseResponseToJSX(response?.result?.data), type: 'bot', createHistory: true, endConversation: false }
                ]);
            } else if (textValue === "Create summary") {
                setMessages((prev: any) => [
                    ...prev,
                    { text: parseResponseToJSX(response?.result?.data), type: 'bot', createHistory: false, endConversation: true }
                ]);
            } else {
                setMessages((prev: any) => [
                    ...prev,
                    { text: response?.result?.data, type: 'bot', createHistory: false, endConversation: false }
                ]);
            }
        }
        else {
            setLoader(false);
            setError({
                show: true,
                class: "show-error",
                message: response?.response?.data?.message
            });
        }
    }

    const inputRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [textValue])

    const parseResponseToJSX = (text: string) => {
        const cleanedText = text.replace(/\*\*(.*)\*\*/g, '$1')
            .replace(/\*(.*)\*/g, '$1');

        const lines = cleanedText.split("\n").map(line => line.trim());
        const elements = lines.map((line, index) => {
            if (line.startsWith("■")) {
                return <h6 key={index}>{line.replace("■", "").trim()}</h6>;
            } else if (line.startsWith("•")) {
                return <li key={index}>{line.replace("•", "").trim()}</li>;
            } else if (line.startsWith("**")) {
                return <h3 key={index}>{line.replace("**", "").trim()}</h3>;
            } else if (line) {
                return <h3 key={index}>{line}</h3>;
            }
            return null;
        });

        const groupedElements: JSX.Element[] = [];
        let currentList: JSX.Element[] = [];

        elements.forEach((element) => {
            if (element?.type === "li") {
                currentList.push(element);
            } else {
                if (currentList.length > 0) {
                    groupedElements.push(<ul key={`ul-${groupedElements.length}`}>{currentList}</ul>);
                    currentList = [];
                }
                if (element) {
                    groupedElements.push(element);
                }
            }
        });
        if (currentList.length > 0) {
            groupedElements.push(<ul key={`ul-${groupedElements.length}`}>{currentList}</ul>);
        }
        return groupedElements;
    };
    const chatEndRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);


    return (
        <>
            {error.show && (
                <CustomError class={`mt-3 ${error?.class} right-side`} message={error?.message} />
            )}
            <div className="chat-box h-100">
                <Row className="h-100 flex-column">
                    <Col className="top-part" xs={12}>
                        <ul className="msg-area">
                            {messages?.map((msg: any, index: number) => (
                                <React.Fragment key={index}>
                                    <li className={`chat-msg ${msg?.type === "user" ? "message-user" : "message-bot"}`}>
                                        {msg.type === 'bot' ? (
                                            <>
                                                {msg?.createHistory ? (
                                                    <>

                                                        <CaseHistorySummary />

                                                        <div className="case-des">
                                                            {msg.text}
                                                        </div>
                                                    </>
                                                ) : msg?.endConversation ?
                                                    <>
                                                        <div className="case-des end-conversation">
                                                            {msg.text}
                                                        </div>
                                                        <EndConversationSummary />
                                                    </> :
                                                    <>
                                                        <TypeAnimation
                                                            sequence={
                                                                [
                                                                    msg.text,
                                                                    (el: any) => el.classList.remove(CURSOR_CLASS_NAME)
                                                                ]
                                                            }
                                                            speed={99}
                                                            className={CURSOR_CLASS_NAME}
                                                            wrapper="div"
                                                            cursor={false}
                                                        />
                                                    </>}
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    {msg.text}
                                                </div>
                                            </>
                                        )}

                                    </li>
                                </React.Fragment>
                            ))}
                            {loader && <Loader />}
                            <div ref={chatEndRef} />
                        </ul>
                    </Col>

                    {!endConversationSummary && (
                        <>
                            {!messages.length && (
                                <Suggestion setTextValue={(value: any) => setTextValue(value)} />
                            )}
                            <Col className="bottom-part" xs={12} id="no-print">
                                <Row className="g-2">
                                    <Col>
                                        <div className="chat-textarea">
                                            <textarea
                                                ref={inputRef}
                                                value={textValue}
                                                maxLength={500}
                                                onChange={(e) => {
                                                    const mainValue = e.target.value;
                                                    setTextValue(mainValue || "")
                                                }}
                                                onKeyDown={(key: any) => {
                                                    if (key.key === "Enter") {
                                                        if (textValue.trim() !== "") {
                                                            fetchMessage();
                                                            key.preventDefault();
                                                            setTextValue("");
                                                            inputRef.current?.focus();
                                                        }
                                                    }
                                                }}
                                                placeholder="Enter Your Input"></textarea>
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <Button className="chat-btn mic-btn" onClick={() => props.openSpeechModal(true)}>

                                            {props.micLoader ? (
                                                <Loader />
                                            ) : (
                                                micIcon()
                                            )}
                                        </Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button className="chat-btn"
                                            onClick={() => fetchMessage()}
                                            disabled={textValue.trim() ? false : true}
                                        >
                                            {sendIcon()}
                                        </Button>
                                    </Col>
                                </Row>
                                <Stack className="chat-btns mt-2 pb-2" direction="horizontal" gap={1}>
                                    {/* {chatType === "paid" && ( */}
                                    <>
                                        {!props.explainTest && (
                                            <Button
                                                variant="secondary"
                                                onClick={() => props.openHearingTestModal(true)}
                                                className="ms-auto"
                                            >
                                                Proceed to Hearing Test
                                            </Button>
                                        )}
                                        {props.explainTest && (
                                            <>
                                                {!caseHistory && (
                                                    <Button
                                                        onClick={() => {
                                                            setCaseHistory(true);
                                                            setTextValue("create case history");
                                                        }}
                                                        variant="secondary"
                                                        className={`${props.explainTest && "ms-auto" || !caseHistory && "ms-auto"}`}
                                                    >
                                                        Case History Summary
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </>

                                    <Button
                                        variant="primary"
                                        className={`end-con ${caseHistory && "ms-auto"}`}
                                        disabled={loader}
                                        onClick={() => props.onEndConversation(true)}
                                    >
                                        End Conversation
                                    </Button>
                                </Stack>
                            </Col>
                        </>
                    )}
                </Row >
            </div >
        </>
    )
}
export default ChatBox;