import { FC, useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row } from "react-bootstrap";
import { pauseIcon, restartIcon } from "../../../utils/Icons";
import Loader from "../../Loader";
import { ReactMic } from "react-mic";
import { audioTranscribe } from "../../../api/endPoints";
import axios from "axios";
import { apiUrl } from "../../../api/baseUrl";

interface SpeechRecognitionText {
    show: boolean;
    micLoader: (value: boolean) => void;
    onClose: () => void;
    setTextValue: (value: any) => void;
}

const SpeechRecognitionText: FC<SpeechRecognitionText> = (props) => {

    const [text, setText] = useState<string>('');

    const [record, setRecord] = useState(false);

    useEffect(() => {
        props.setTextValue("");
        if (props.show) {
            props.setTextValue("");
            setText("");
            startListening();
        }
    }, [props.show]);


    const startListening = () => {
        setText("");
        setRecord(true);
    };
    const stopListening = () => {
        SpeechRecognition.stopListening();
        setRecord(false);
    };

    const sendAudio = async (recordedBlob: any) => {
        props.micLoader(true);
        let data = new FormData();
        data.append("audio", recordedBlob?.blob, "audio.wav");
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(`${apiUrl}/${audioTranscribe}`, data, {
                headers: {
                    "Content-Type": "multipart/media",
                    Authorization: `Bearer ${token}`
                }
            });
            if (response?.data?.flag) {
                const resultData = (response.data as { result: { data: string } }).result.data;
                setText(resultData);
                props.micLoader(false);
            }
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                if (error?.response?.data?.message === "Invalid or expired token" || error?.response?.data?.message === "Session not found. Please log in again." || error?.status === 401) {
                    const keyToKeep = "googleToken";
                    const valueToKeep = localStorage.getItem(keyToKeep);
                    localStorage.clear();
                    if (valueToKeep) {
                        localStorage.setItem(keyToKeep, valueToKeep);
                    }
                    window.location.reload();
                }
            }
            console.log(error, "error");
            return error;

        }
    }

    return (
        <>
            <Modal className="speech-modal" size="lg" show={props.show}
                onHide={() => {
                    setText("");
                    props.onClose();
                    setRecord(false);
                }}
                centered>
                <Modal.Body>
                    <Row className="justify-content-between align-items-center px-4 speech-height">
                        <Col md={{ order: 1, span: "auto" }} xs={{ order: 2, span: 12 }}>
                            <h4>
                                Listening
                            </h4>
                        </Col>
                        <Col md={{ order: 2, span: "auto" }} xs={{ order: 1, span: 12 }}>
                            <div className="rounds-btn">
                                <ReactMic
                                    record={record}
                                    className="sound-wave"
                                    onStop={sendAudio}
                                    mimeType="audio/wav" />
                                {record ? (
                                    <Button className="pause-btn" onClick={stopListening}>
                                        {pauseIcon()}
                                    </Button>
                                ) : (
                                    <Button className="restart-btn" onClick={startListening}>
                                        {restartIcon()}
                                    </Button>
                                )}
                            </div>
                        </Col>

                        <Col xs={{ order: 3, span: 12 }} className="text-center">

                            {text === "" ?
                                <>
                                    <Loader />
                                </>
                                :
                                <>
                                    <div className="loader">
                                        <p className="text-white mt-3">{text}</p>
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => {
                                                props.setTextValue(text);
                                                props.onClose();
                                            }}
                                        >Ok</Button>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >
        </>
    )
}
export default SpeechRecognitionText;