import { FC, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Persona from "../components/Persona";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPersonas } from "../api/request";
import FreeModal from "../components/admin/FreeModal";

const PersonaSelection: FC = () => {
    const navigate = useNavigate();

    const [personaList, setPersonaList] = useState<any>([]);

    const chatMode = localStorage.getItem('chat-mode');

    const fetchPersonas = async () => {
        let param = `?type=${chatMode}`;
        const response = await getPersonas(param);
        if (response?.flag) {
            setPersonaList(response?.result?.data)
        }
    }

    const [activeSlide, setActiveSlide] = useState(3);
    const [countActive, setCountActive] = useState(1);
    const [free, setFree] = useState(false);
    const [oldSlide, setOldSlide] = useState(5);

    useEffect(() => {
        setOldSlide(personaList.length - 1);
    }, [personaList.length]);

    const [activeNo, setActiveNo] = useState(false);
    const [threeSlide, setThreeSlide] = useState(false);

    useEffect(() => {
        if (!personaList.length) {
            fetchPersonas();
        }
    }, [chatMode]);

    useEffect(() => {
        setActiveSlide(3);
    }, []);

    useEffect(() => {
        const matchComingSoon = personaList[countActive - 1];
        const comingSoon = matchComingSoon?.avalability && matchComingSoon?.avalability === "comming_soon" ? true : false;
        setActiveNo(comingSoon);
    }, [countActive, activeNo]);

    const handleClick = () => {
        const selectPersonas = personaList[countActive - 1];
        const personaName = selectPersonas?.name;
        localStorage.setItem('Persona', personaName);
        navigate('/chat-screen');
    }

    var settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        speed: 500,
        centerMode: true,
        dots: false,
        nextArrow: <img
            src={`${process.env.PUBLIC_URL}/assets/img/right-arrow.svg`}
            alt="right-arrow"
        />,
        prevArrow: <img
            src={`${process.env.PUBLIC_URL}/assets/img/left-arrow.svg`}
            alt="left-arrow"
        />,
        className: "center",
        centerPadding: "0px",
        beforeChange: (current: any, next: any) => {
            const totalSlides = personaList?.length && personaList?.length;
            let activeSlide = (next + 3) % totalSlides;
            if (activeSlide === 0 && next + 3 !== 0) {
                activeSlide = totalSlides;
            }
            let oldSlide = next - 1;
            if (oldSlide === 0) {
                oldSlide = totalSlides;
            }
            if (oldSlide === -1) {
                oldSlide = personaList?.length - 1;
            }
            setActiveSlide(activeSlide);
            setOldSlide(oldSlide);
            if (personaList?.length <= 3) {
                setCountActive(next + 1);
                setThreeSlide(true);
            }
            else {
                setCountActive(next + 1);
                setThreeSlide(false);
            }
        },
        responsive:
            [

                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerPadding: "80px",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        centerPadding: "60px",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
    };

    return (
        <>
            <FreeModal show={free} onClose={() => setFree(false)} handleClick={handleClick} />
            <section className="persona-section">
                <Container>
                    {personaList.length > 0 && (
                        <Row className="justify-content-center">

                            <Col
                                xxl={12}
                                xl={11}
                                md={12}
                                sm={11}
                                xs={12}
                            >

                                <Slider
                                    {...settings}
                                >

                                    {personaList?.length > 0 && personaList?.map((item: any, index: number) => (
                                        <div
                                            key={index}
                                            className={`${activeSlide === index + 1 ? 'lastActive' : ""}  ${oldSlide === index + 1 ? 'firstActive' : ""} ${countActive === (threeSlide ? index : index + 1) ? "slick-center" : ""}`}
                                        >
                                            <div
                                                className={`item-inside ${personaList && personaList.length <= 3 ? " px-1" : ""}`}
                                            >
                                                <div
                                                    className={`person-list`}
                                                >
                                                    <Persona
                                                        coming={item?.avalability}
                                                        about={item?.baseline?.profile?.description}
                                                        imgSrc={item?.image_url ? item?.image_url : `${process.env.PUBLIC_URL + '/assets/img/dummy.jpg'}`}
                                                        name={item?.baseline?.profile?.name}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>

                                <Row className="justify-content-center">
                                    <Col xs="auto">
                                        <div className="count-middle text-center mt-2">
                                            {countActive} / {personaList?.length}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-lg-5 mt-2">
                                    {activeNo === false ?
                                        <>
                                            {chatMode === "paid" && (
                                                <Button
                                                    variant=""
                                                    onClick={() =>
                                                        handleClick()
                                                    }
                                                    className="start-btn">Start</Button>
                                            )}
                                            {chatMode === "free" && (
                                                <Button variant=""

                                                    onClick={() => {
                                                        setFree(true);
                                                    }}
                                                    className="start-btn"
                                                >Start</Button>
                                            )}
                                        </>
                                        :
                                        <Button style={{ opacity: "0" }} disabled={activeNo} variant=""
                                            className="start-btn"
                                        >Coming Soon</Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </section >

        </>
    )
}
export default PersonaSelection;