import { FC, useEffect, useRef, useState } from "react";
import Steps from "../components/Steps";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Persona from "../components/Persona";
import ChatBox from "../components/ChatBox";
import EndConversation from "../components/modals/EndConversation";
import HearingTest from "../components/modals/HearingTest/HearingTest";
import HearingTestImage from "../components/modals/HearingTest/HearingTestImage";
import SpeechRecognitionText from "../components/modals/speechRecognition/SpeechRecognition";
import { useNavigate } from "react-router-dom";
import { getPersonas } from "../api/request";

interface Data {
    provide: string;
    follow: string;
    reaction: string;
}
interface ChatScreen {
    endSummaryButton: boolean;
}


const ChatScreen: FC<ChatScreen> = (props) => {
    const [isEndConversation, setIsEndConversation] = useState(false);
    const [openHearingTestModal, setOpenHearingTestModal] = useState(false);
    const [explainTest, setExplainTest] = useState(false);
    const [openHearingImage, setOpenHearingImage] = useState(false);
    const [speechModal, setSpeechModal] = useState(false);
    const [speechText, setSpeechText] = useState<string>("");
    const [endSummary, setEndSummary] = useState<boolean>(false);
    const [continueCaseHistory, setCaseHistory] = useState(false);
    const [loaderCheck, setLoaderCheck] = useState(false);
    const [pdfShow, setPdfShow] = useState(false);
    const [personaDetails, setPersonaDetails] = useState<any>([]);


    useEffect(() => {
        window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
        window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
        const handlePopState = (event: PopStateEvent) => {
            if (event.state) {
                const isConfirmed = window.confirm('The chat history will be lost. Are you sure you want to continue?');
                if (isConfirmed) {
                    window.location.href = '/chat-mode';
                } else {

                    window.history.pushState({ name: "browserBack" }, "on browser back click", window.location.href);
                }
            }
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        setEndSummary(props.endSummaryButton);
    }, [endSummary, props.endSummaryButton]);

    console.log(endSummary, "endSummaryss");



    const chatMode = localStorage.getItem('chat-mode');
    const selectedPersona = localStorage.getItem('Persona');

    const [traits, setTraits] = useState<Data | null>(null);

    useEffect(() => {
        const storedData = localStorage.getItem('listKey');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setTraits(parsedData);
        }
    }, []);

    useEffect(() => {
        fetchPersonas();
    }, []);

    const fetchPersonas = async () => {
        let param = `?type=${chatMode}&name=${selectedPersona}`;
        const response = await getPersonas(param);
        if (response?.flag) {
            setPersonaDetails(response?.result?.data);
        }
    }


    return (
        <>
            {/* <Button onClick={() => handleBeforeUnload}>sdfsd</Button> */}
            <EndConversation show={isEndConversation} onClose={() => setIsEndConversation(false)} setEndConversationSummary={setEndSummary} />
            <HearingTest personaDetails={personaDetails} show={openHearingTestModal} onClose={() => setOpenHearingTestModal(false)} explainTest={setExplainTest} continueHistory={setCaseHistory} pdfShow={setPdfShow} />
            <HearingTestImage show={openHearingImage} onClose={() => setOpenHearingImage(false)} />
            <SpeechRecognitionText show={speechModal} setTextValue={(value: string) => setSpeechText(value)} onClose={() => setSpeechModal(false)} micLoader={(value: boolean) => setLoaderCheck(value)} />
            <section className="h-100 p-0">
                <Container className="h-100">
                    <Steps continueCase={continueCaseHistory} explainTest={explainTest} />
                    <Row className="mt-2 main-chat">
                        <Col lg="auto" className="">
                            <Persona
                                coming={""}
                                name={personaDetails[0]?.baseline?.profile?.name}
                                about={personaDetails[0]?.baseline?.profile?.description}
                                imgSrc={personaDetails[0]?.image_url ? personaDetails[0]?.image_url : `${process.env.PUBLIC_URL + '/assets/img/dummy.jpg'}`}
                            />
                            {pdfShow &&
                                <div className="hearing-test-img text-center mt-2 d-lg-block d-none">
                                    <Image onClick={() => setOpenHearingImage(true)} src={process.env.PUBLIC_URL + '/assets/img/hearing-test.png'} alt="" />
                                </div>
                            }
                            {chatMode === "free" && (
                                <>
                                    <div className="traits-display mt-2">
                                        <p>Provide information : <span>{traits?.provide}</span></p>
                                        <p>Follow recommendations : <span>{traits?.follow}</span></p>
                                        <p>Emotional reaction : <span>{traits?.reaction}</span></p>
                                    </div>
                                </>
                            )}
                        </Col>
                        <Col>
                            <ChatBox
                                micLoader={loaderCheck}
                                personaDetails={personaDetails}
                                explainTest={explainTest}
                                speechTexts={speechText}
                                endSummary={endSummary}
                                onEndConversation={(value: any) => setIsEndConversation(value)}
                                openHearingTestModal={(value: any) => setOpenHearingTestModal(value)}
                                openSpeechModal={(value: any) => setSpeechModal(value)}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default ChatScreen;