export const eyeOff = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25.98 22.264">
                <path id="Icon_ionic-md-eye-off" data-name="Icon ionic-md-eye-off" d="M15.243,9.185a5.889,5.889,0,0,1,5.908,5.862,5.639,5.639,0,0,1-.423,2.145l3.45,3.421a13.861,13.861,0,0,0,4.053-5.566,13.991,13.991,0,0,0-17.7-7.972l2.551,2.534A5.842,5.842,0,0,1,15.243,9.185ZM3.433,5.99l2.7,2.673.545.539A13.794,13.794,0,0,0,2.25,15.046a14.012,14.012,0,0,0,18.165,7.8l.5.493,3.461,3.421,1.5-1.49L4.929,4.5Zm6.528,6.476,1.832,1.815a3.321,3.321,0,0,0-.093.76,3.525,3.525,0,0,0,3.543,3.514,3.332,3.332,0,0,0,.765-.093l1.832,1.815a5.877,5.877,0,0,1-8.506-5.241A5.791,5.791,0,0,1,9.961,12.466Zm5.091-.91,3.722,3.693.023-.186a3.525,3.525,0,0,0-3.543-3.514Z" transform="translate(-2.25 -4.5)" fill="#fff" opacity="0.8" />
            </svg>
        </>
    )
}
export const eyeOn = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 25.98 17.513">
                <path id="Icon_ionic-md-eye" data-name="Icon ionic-md-eye" d="M15.24,7.383A13.969,13.969,0,0,0,2.25,16.139a14.013,14.013,0,0,0,25.98,0A13.969,13.969,0,0,0,15.24,7.383Zm0,14.6a5.84,5.84,0,1,1,5.9-5.84A5.888,5.888,0,0,1,15.24,21.979Zm0-9.342a3.5,3.5,0,1,0,3.543,3.5A3.534,3.534,0,0,0,15.24,12.637Z" transform="translate(-2.25 -7.383)" fill="#cdd3d9" />
            </svg>
        </>
    )
}
export const submitIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22.783" height="19.528" viewBox="0 0 22.783 19.528">
                <path id="Icon_material-send" data-name="Icon material-send" d="M3.011,24.028l22.772-9.764L3.011,4.5,3,12.094l16.273,2.17L3,16.434Z" transform="translate(-3 -4.5)" fill="#fff" />
            </svg>
        </>
    )
}
export const closeIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="39.814" height="39.814" viewBox="0 0 39.814 39.814">
                <path id="close-filled" d="M22.157,2.25A19.784,19.784,0,0,0,2.25,22.157,19.784,19.784,0,0,0,22.157,42.064,19.784,19.784,0,0,0,42.064,22.157,19.784,19.784,0,0,0,22.157,2.25Zm7.678,29.86-7.678-7.678L14.479,32.11,12.2,29.835l7.678-7.678L12.2,14.479,14.479,12.2l7.678,7.678L29.835,12.2l2.275,2.275-7.678,7.678,7.678,7.678Z" transform="translate(-2.25 -2.25)" fill="#fff" />
            </svg>

        </>
    )
}
export const closeIconFlat = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
                <path d="M 4.2382812 2.9882812 A 1.250125 1.250125 0 0 0 3.3671875 5.1347656 L 10.232422 12 L 3.3613281 18.869141 A 1.2512475 1.2512475 0 1 0 5.1308594 20.638672 L 12 13.767578 L 18.865234 20.632812 A 1.250125 1.250125 0 1 0 20.632812 18.865234 L 13.767578 12 L 20.625 5.1425781 A 1.250125 1.250125 0 1 0 18.857422 3.375 L 12 10.232422 L 5.1347656 3.3671875 A 1.250125 1.250125 0 0 0 4.2382812 2.9882812 z" fill="#fff"></path>
            </svg>
        </>
    )
}
export const micIcon = () => {
    return (
        <>
            <svg id="mic" xmlns="http://www.w3.org/2000/svg" width="36" height="28" viewBox="0 0 62 62">
                <path id="Path_17" data-name="Path 17" d="M26.438,42.625a9.691,9.691,0,0,0,9.688-9.687V9.688a9.688,9.688,0,0,0-19.375,0v23.25A9.691,9.691,0,0,0,26.438,42.625ZM40,27.125v5.813a13.562,13.562,0,1,1-27.125,0V27.125H9v5.813A17.446,17.446,0,0,0,24.5,50.272v7.853H16.75V62H36.125V58.125h-7.75V50.272a17.446,17.446,0,0,0,15.5-17.334V27.125Z" transform="translate(2.625)" fill="#f1582c" />
                <path id="Path_18" data-name="Path 18" d="M0,0H62V62H0Z" fill="none" />
            </svg>
        </>
    )
}
export const pauseIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                <path id="stop" d="M11.786,7.5H33.214A4.286,4.286,0,0,1,37.5,11.786V33.214A4.286,4.286,0,0,1,33.214,37.5H11.786A4.286,4.286,0,0,1,7.5,33.214V11.786A4.286,4.286,0,0,1,11.786,7.5Z" transform="translate(-7.5 -7.5)" fill="#fff" />
            </svg>
        </>
    )
}
export const restartIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="39.95" height="40" viewBox="0 0 39.95 40">
                <path id="Icon_open-reload" data-name="Icon open-reload" d="M20,0A20,20,0,1,0,34.2,34.2l-3.6-3.6A15.011,15.011,0,1,1,19.95,5,14.546,14.546,0,0,1,30.4,9.55L24.95,15h15V0L34,5.95A19.888,19.888,0,0,0,19.95,0Z" fill="#fff" />
            </svg>
        </>
    )
}
export const infoIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
                <g id="Group_65" data-name="Group 65" transform="translate(-440 -883)">
                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="23" cy="23" r="23" transform="translate(440 883)" fill="#fff" opacity="0.22" />
                    <text id="_" data-name="!" transform="translate(462 916)" fill="#fff"
                        style={{
                            fontSize: '30px',
                            fontWeight: "500",
                            fontFamily: "Poppins-Medium, Poppins"
                        }}
                    ><tspan x="-4.815" y="0">!</tspan></text>
                </g>
            </svg>

        </>
    )
}
export const sendIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32.004" height="24" viewBox="0 0 32.004 32.004">
                <g id="send" transform="translate(0.004)">
                    <path id="Path_14" data-name="Path 14" d="M37.661,2.27a.944.944,0,0,1-.213.307L17.28,22.744l5.7,9.494a1.062,1.062,0,0,0,1.893-.127Z" transform="translate(-5.761 -0.757)" fill="#001e3e" />
                    <path id="Path_15" data-name="Path 15" d="M10.013,20.527l-9.494-5.7a1.062,1.062,0,0,1,.127-1.893L30.493.14a1.113,1.113,0,0,0-.313.22Z" transform="translate(0 -0.047)" fill="#001e3e" />
                    <path id="Path_16" data-name="Path 16" d="M0,0H32V32H0Z" transform="translate(-0.001)" fill="none" />
                </g>
            </svg>
        </>
    )
}
